// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from './components/MainPage'; // This would be your current App component's content, refactored into MainPage.js
import About from './components/About'; // Your About page component
import YourData from './components/YourData'; // Your YourData page component
import How from './components/How'; // Your How page component
import './App.css';

function App() {
  return (
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/yourdata" element={<YourData />} />
        <Route path="/how" element={<How />} />
      </Routes>
  );
}

export default App;
