// About.js
import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router';

function YourData() {
    const navigate = useNavigate();

    return (
        <div style={{
          backgroundImage: 'url(https://images.unsplash.com/photo-1620207418302-439b387441b0?q=80&w=2534&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA=)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Container maxWidth="sm" style={{ textAlign: 'center', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
            <Typography variant="h3" gutterBottom sytle={{ marginBottom: '10px'}}>
              How to download your data
            </Typography>
            <Typography variant="body1" color={'silver'} style={{ marginBottom: '10px'}}>
            To download your data from Google, follow <a href='https://support.google.com/accounts/answer/3024190?hl=fr' color='silver'>this link</a>.
            </Typography>
            <Typography variant="body1" color={'silver'} style={{ marginBottom: '10px'}}>
            To download your data from Facebook, follow <a href='https://www.facebook.com/help/212802592074644' color='silver'>this link</a>.
            </Typography>
            <Typography variant="h4" color={'white'} style={{ marginBottom: '20px'}}>
            Privacy notice
            </Typography>
            <Typography variant="body1" color={'silver'} style={{ marginBottom: '10px'}}>
            By sending us your data, you agree to our privacy policy and give us permission to store and process your data. We will not share your data with third parties. You can request to delete your data at any time by contacting us at info@ritsl.ch
            </Typography>
            <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
            Back to homepage
            </Button>
          </Container>
        </div>
    );
}

export default YourData;
