import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router';

function How() {
    const navigate = useNavigate();

    return (
        <div className='how'>
          <Container maxWidth="sm" style={{
              textAlign: 'center',
              padding: '30px', // Set a padding value to ensure content does not touch the edges
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100vh'
          }}>
            <Typography variant="h3" gutterBottom style={{ marginBottom: '10px' }}> 
              How does it work
            </Typography>
            <Typography variant="body1" color="silver" style={{ marginBottom: '10px' }}>
              We use your data to provide you with a personalized analysis of the link between your symptoms and your personality. 
              We use tools such as machine learning and natural language processing to analyze your data.
              Our analysis is based on the methods of the Swiss psychiatrist Carl Gustav Jung, mixed with modern psychological theories, astrology, mythology, and other fields of knowledge.
              We proceed as any analyst would have proceeded, but with the help of modern technologies.
              We gather information, we analyze it, and we provide you with a report.
            </Typography>
            <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
              Back to homepage
            </Button>
          </Container>
        </div>
    );
}

export default How;
