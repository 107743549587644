import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Link, Avatar, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AWS from 'aws-sdk';

var Airtable = require('airtable'); 
var base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

const S3_BUCKET =process.env.REACT_APP_BUCKET_NAME;
const REGION =process.env.REACT_APP_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET},
  region: REGION,
})

const profilePictures = [
  'https://plus.unsplash.com/premium_photo-1688891564708-9b2247085923?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1619895862022-09114b41f16f?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1517849845537-4d257902454a?q=80&w=2535&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://plus.unsplash.com/premium_photo-1671656349322-41de944d259b?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1639747280804-dd2d6b3d88ac?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1544723795-3fb6469f5b39?q=80&w=2578&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://plus.unsplash.com/premium_photo-1666264200737-acad542a92ff?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
];

function MainPage() {
  const [formState, setFormState] = useState({
    name: '',
    dateOfBirth: '',
    symptom: '',
    restraints: '',
    additionalInfo: '',
    email: '',
    file: null, // Add a file state
  });
  
  const [isFormVisible, setIsFormVisible] = useState(false); // New state to control form visibility
  const [submissionMessage, setSubmissionMessage] = useState(''); // New state for submission message

  // Reset form and show submission message
  const handleSuccess = () => {
    setIsFormVisible(false); // Hide the form
    setSubmissionMessage("Thank you for your submission, we will process your data and come back to you shortly with a full report");
    setTimeout(() => setSubmissionMessage(''), 5000); // Clear message after 5 seconds
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === 'file') { // Handle file separately
    setFormState(prevState => ({
      ...prevState,
      [name]: files[0], // Store the first file selected
    }));
  } else {
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let fileUrl = ""; // Variable to store the file URL if uploaded
  
    // Helper function to upload file to S3 and return the URL
    const uploadFileToS3 = (file) => {
      return new Promise((resolve, reject) => {
        const params = {
          ACL: 'public-read', // Adjust the ACL according to your requirements
          Body: file,
          Bucket: S3_BUCKET,
          Key: file.name,
        };
  
        myBucket.putObject(params)
          .on('httpUploadProgress', (evt) => {
            // Optionally, handle progress updates here
            console.log(`Uploaded: ${evt.loaded} of ${evt.total} bytes`);
          })
          .send((err, data) => {
            if (err) reject(err);
            else {
              // Construct the file URL and resolve it
              const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${encodeURIComponent(params.Key)}`;
              resolve(fileUrl); // Resolve the promise with the URL
            }
          });
      });
    };
  
    if (formState.file) {
      try {
        fileUrl = await uploadFileToS3(formState.file); // Upload file and get the URL
        console.log('File uploaded successfully:', fileUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
        return; // Stop execution if file upload fails
      }
    }
  
    // Now, include fileUrl in the Airtable record, if available
    base('Table 1').create([
      {
        fields: {
          'Name': formState.name,
          'Date of Birth': formState.dateOfBirth,
          'Symptom': formState.symptom,
          'Restraints': formState.restraints, 
          'Additional Information': formState.additionalInfo,
          'Email': formState.email,
          'File URL': fileUrl, // Add this line to include the file URL
        },
      },
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function(record) {
        console.log('Record added:', record.getId());
      });
      handleSuccess(); // Call on successful submission
    });
  };

  const toggleFormVisibility = () => setIsFormVisible(!isFormVisible); // Function to toggle form visibility

  return (
    <div className="main-page">
      {/* Left Section */}
      <Container maxWidth="sm" style={{ textAlign: 'center', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>
      {submissionMessage && <Typography color="greenyellow">{submissionMessage}</Typography>}
      <Typography variant="h3" gutterBottom style={{ margin: '10px 0' }}>
          Healing through <br></br> self-knowledge
        </Typography>
        <Typography variant="body2" style={{ color: 'silver', margin: '10px 0' }}>
          "It is more important to know what sort of person has a disease than to know what sort of disease a person has." - Hippocrates
        </Typography>
        <Link variant="body1" component={RouterLink} to="/about" color="#ffffff" style={{ marginBottom: '10px' }}>Discover more</Link>
        <Button variant="contained" color='secondary' style={{ marginBottom: '10px' }} onClick={toggleFormVisibility}>
          Try it out
        </Button>
        <Typography variant="body2" gutterBottom style={{ color: '#ffffff', margin: '10px 0' }}>
          500+ people already on their healing journey
        </Typography>
        {/* Reduced size Avatars */}
        <Stack direction="row" spacing={1} justifyContent="center">
          {profilePictures.map((src, index) => (
            <Avatar key={index} src={src} alt="Profile Picture" style={{ width: 24, height: 24, marginBottom: '10px' }} />
          ))}
        </Stack>
        <Link variant="body1" component={RouterLink} to="/how" color="#ffffff" style={{ marginBottom: '10px' }}>How does it work</Link>
      </Container>
      {/* Right Section */}
      {/* Conditional rendering based on isFormVisible */}
      {isFormVisible && (
        <Container maxWidth="sm" style={{
          padding: '20px',
          display: 'flex', // This makes the container a flex container
          flexDirection: 'column', // Arranges children in a column
          justifyContent: 'center', // Centers children along the cross axis
          flexGrow: 1, // Allows the container to grow to fill available space
          backgroundColor: 'rgba(255,255,255,0.8)',
          }}>
          <form onSubmit={handleSubmit} style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}>          
          <TextField
            name="name"
            label="Name"
            color='secondary'
            value={formState.name}
            onChange={handleChange}
          />
          <TextField
            name="dateOfBirth"
            label="Date of Birth"
            type="date"
            color='secondary'
            InputLabelProps={{
              shrink: true,
            }}
            value={formState.dateOfBirth}
            onChange={handleChange}
          />
          <TextField
            name="symptom"
            label="Symptoms"
            placeholder='What are the symptoms you are experiencing?'
            color='secondary'
            value={formState.symptom}
            onChange={handleChange}
          />
          <TextField
            name="restraints"
            label="Restraints"
            placeholder='Things that the symptoms restrain you from doing (e.g : work, social activities, etc.)'
            color='secondary'
            value={formState.restraints}
            onChange={handleChange}
          />
          <TextField
            name="additionalInfo"
            label="Additional Information"
            color='secondary'
            placeholder='Any additional information that would help understand your symptoms better (e.g : how they started, how they evolved, etc.)'
            multiline
            rows={4}
            value={formState.additionalInfo}
            onChange={handleChange}
          />
          <TextField
            name="email"
            label="Email"
            color='secondary'
            type="email"
            value={formState.email}
            onChange={handleChange}
          />
          <TextField
            name="file"
            type="file"
            color='secondary'
            onChange={handleChange}
            InputLabelProps={{
            shrink: true,
              }}
              helperText="Optional: Upload a file with additional information such as your journals, Facebook or Google data, etc."
            />
            <Link variant="body2" component={RouterLink} to="/yourdata" color="primary" style={{ marginTop: '5px' }}>
            Learn how to download your data from Facebook or Google
            </Link>
            <Button type="submit" variant="contained" color="secondary">
              Submit
            </Button>
          </form>
        </Container>
      )}
    </div>
  );
}

export default MainPage;
