// About.js
import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router';

function About() {
    const navigate = useNavigate();

    return (
        <div className='about'>
          <Container maxWidth="sm" style={{ textAlign: 'center', padding: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h3" gutterBottom sytle={{ marginBottom: '10px'}}>
              About this project
            </Typography>
            <Typography variant="body" gutterBottom color={'silver'} sytle={{ marginBottom: '10px'}}>
            'Until you make the unconscious conscious, it will direct your life and you will call it fate.” - C.G Jung
            </Typography>
            <Typography variant="body1" color={'silver'} style={{ marginBottom: '10px'}}>
            RITSL for  <a href='https://ritsl.ch' color='silver'>Research Institute in the Technologies of the Self</a>, is a private services company whose aim is to improve human capabilities through the use of computer technologies and artificial intelligence.
            </Typography>
            <Typography variant="body1" color={'silver'} style={{ marginBottom: '10px'}}>
            With the JungSON project, a contraction of Jung and the JSON format used to analyse data, we aim to provide psycho-analytical tools to help people understand the symbolic meaning of their symptoms. We believe in psychosomatic medicine. Understanding how the unconscious speaks through the symptoms in the body, is the best way to make the unconscious conscious, and finally heal. 
            </Typography>
            <Typography variant="body1" color={'silver'} style={{ marginBottom: '20px'}}>
            Our goal is to help people understand the meaning of their symptoms, and to provide them with the tools to heal themselves. To achieve this, we blend together the latest technologies in artificial intelligence, machine learning and traditional astrology, symbolism, and psychoanalysis as developed by Freud, Jung, Groddeck, and other important figures.
            </Typography>
            <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
            Back to homepage
            </Button>
          </Container>
        </div>
    );
}

export default About;
